import Head from 'next/head';
import {useEffect} from 'react';

import withErrorBoundary from '@/ad-hocs/withErrorBoundary';
import ConsoleCommands from '@/mechanisms/ConsoleCommands';
import IEnhancedAppProps from '@/models/IEnhancedAppProps';
import {ILayoutComponent, ILayoutProps} from '@/models/ILayout';
import {__DEV__} from '@/utils/constants';

const DefaultLayout: ILayoutComponent = ({children}: ILayoutProps) => <>{children}</>;

const GOOGLE_TAG_MANAGER_SCRIPT = `
(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-T9BKZFF');
`;

const GOOGLE_TAG_MANAGER_NOSCRIPT = `
<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-T9BKZFF"
height="0" width="0" style="display:none;visibility:hidden"></iframe>
`;

function MyApp({Component: Page, pageProps}: IEnhancedAppProps) {
  const Layout: ILayoutComponent = Page.layout || DefaultLayout;

  useEffect(() => {
    __DEV__ && !ConsoleCommands.isActivated() && ConsoleCommands.activate();
  }, []);

  return (
    <>
      <Head>
        <script dangerouslySetInnerHTML={{__html: GOOGLE_TAG_MANAGER_SCRIPT}} />
        <title>{process.env.NEXT_PUBLIC_PROJECT_NAME}</title>
        <link rel='preconnect' href='https://fonts.gstatic.com' />
        <link
          href='https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap'
          rel='stylesheet'
        />
      </Head>
      <noscript dangerouslySetInnerHTML={{__html: GOOGLE_TAG_MANAGER_NOSCRIPT}}></noscript>
      <Layout>
        <Page {...pageProps} />
      </Layout>
    </>
  );
}

export default withErrorBoundary(MyApp);
