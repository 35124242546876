import * as Sentry from '@sentry/node';
import {Contexts, ScopeContext} from '@sentry/types';

class ErrorReporting {
  public captureError(e: unknown, contexts?: Contexts, scope?: Partial<ScopeContext>): void {
    Sentry.captureException(e, {
      contexts,
      ...scope,
      tags: {source: 'manual-capture', ...scope?.tags},
    });
    console.error(e, contexts);
  }

  public emitWarn(message: string): void;
  public emitWarn(message: string, context?: unknown): void;
  public emitWarn(message: string, messageOrContext?: unknown): void {
    console.warn(message, messageOrContext);
  }
}

export default new ErrorReporting();
