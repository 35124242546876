import {defineWindowProp, isWindowPropDefined} from '@/utils/window-utils';

interface ICommandsExecutionEnvironment {
  [key: string]: unknown;
}

class ConsoleCommands {
  private environment: ICommandsExecutionEnvironment = {};

  public constructor(private readonly windowProp: string) {}

  public activate(): void {
    defineWindowProp(this.windowProp, this.environment);
  }

  public isActivated(): boolean {
    return isWindowPropDefined(this.windowProp);
  }

  public register(command: string, callback: (...args: unknown[]) => unknown): void {
    Object.defineProperty(this.environment, command, {
      value: callback,
    });
  }

  public isRegistered(command: string): boolean {
    return this.environment.hasOwnProperty(command);
  }

  public unregister(command: string): void {
    delete this.environment[command];
  }
}

export default new ConsoleCommands('app');
