export function defineWindowProp(prop: string, value: unknown) {
  if (typeof window === 'undefined') throw new Error('`window` is not available.');

  Object.defineProperty(window, prop, {value: value});
}

export function isWindowPropDefined(prop: string): boolean {
  return window[prop as keyof Window] !== undefined;
}

export function undefineWindowProp(prop: string) {
  delete window[prop as keyof Window];
}
