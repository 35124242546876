import {RewriteFrames} from '@sentry/integrations';
import * as Sentry from '@sentry/node';
import {Scope} from '@sentry/node';
import {StackFrame} from '@sentry/types';

import {__DEV__, __ENV__} from '@/utils/constants';

class SentryBox {
  public initialize() {
    const sentryDSN: string | undefined = process.env.NEXT_PUBLIC_SENTRY_DSN;

    if (sentryDSN) {
      const integrations = [];
      const isServer: boolean = `${process.env.NEXT_IS_SERVER}`.toLowerCase() === 'true' || false;
      const serverRootDir: string | undefined = process.env.NEXT_PUBLIC_SENTRY_SERVER_ROOT_DIR;
      const releaseID: string | undefined = process.env.NEXT_PUBLIC_RELEASE_ID;

      if (isServer && serverRootDir) {
        /*
         * For Node.js, rewrite Error.stack to use relative paths, so that source
         * maps starting with ~/_next map to files in Error.stack with path
         * app:///_next
         */
        integrations.push(
          new RewriteFrames({
            iteratee: (frame: StackFrame) => {
              frame.filename = frame.filename?.replace(serverRootDir, 'app:///');
              frame.filename = frame.filename?.replace('.next', '_next');
              return frame;
            },
          })
        );
      }

      Sentry.init({
        enabled: __DEV__,
        integrations,
        dsn: sentryDSN,
        environment: __ENV__,
        release: releaseID,
      });

      Sentry.configureScope((scope: Scope) => {
        scope.setTag('source', 'wildly-caught');
      });
    }
  }
}

export default new SentryBox();
