import NextError from 'next/error';
import React, {ErrorInfo, PureComponent, ReactNode} from 'react';

import ErrorReporting from '@/mechanisms/ErrorReporting';

export interface IErrorBoundaryProps {
  children: ReactNode;
}
interface IState {
  hasError: boolean;
}

export default class ErrorBoundary extends PureComponent<IErrorBoundaryProps, IState> {
  public state: IState = {hasError: false};

  static getDerivedStateFromError(): IState {
    return {hasError: true};
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    ErrorReporting.captureError(error, {errorInfo: {...errorInfo}}, {tags: {source: 'error-boundary'}});
  }

  render() {
    const {children} = this.props;
    const {hasError} = this.state;

    if (hasError) return <NextError statusCode={500} title={'Something went wrong...'} />;
    else return children;
  }
}
