import {getDisplayName} from 'next/dist/next-server/lib/utils';
import React, {ComponentType, forwardRef, ForwardRefExoticComponent, PropsWithoutRef, RefAttributes} from 'react';

import ErrorBoundary from './ErrorBoundary';

export default function withErrorBoundary<P extends object>(WrappedComponent: ComponentType<P>) {
  type Ref = ComponentType<P>;

  const forwardRefFunc: ForwardRefExoticComponent<PropsWithoutRef<P> & RefAttributes<Ref>> = forwardRef<Ref, P>(
    (props, ref) => {
      return (
        <ErrorBoundary>
          <WrappedComponent {...(props as P)} ref={ref} />
        </ErrorBoundary>
      );
    }
  );

  forwardRefFunc.displayName = `withErrorBoundary(${getDisplayName(WrappedComponent)})`;

  return forwardRefFunc;
}
